.close {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 1.25rem;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.loading-text {
  text-align: center;
  color: #000000;
  font-weight: 700;
  font-family: var(--theme-body-font);
}

.sm-progress {
  border-radius: 0px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.5);
}
.sm-progress .sm-progressbar {
  border-radius: 0px;
  background-color: var(--theme-button-color);
}

.border-radius-top-left {
  border-top-left-radius: 10px;
}

.border-radius-top-right {
  border-top-right-radius: 10px;
}

.border-radius-bottom-left {
  border-bottom-left-radius: 10px;
}

.border-radius-bottom-right {
  border-bottom-right-radius: 10px;
}

.register {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #000000;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;
}
.register .form {
  padding: 30px 10px;
  height: auto;
  font-family: var(--theme-body-font);
  background-color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
}
.register .form input {
  width: 300px;
  margin: 3px;
  border-radius: 4px;
  padding: 6px;
  color: #000000;
  border: 1px solid #000000;
}
.register .form input[type=button] {
  margin: 3px;
  background: var(--theme-button-color2);
  border: 1px solid #000000;
  color: #ffffff;
  font-weight: bold;
  width: 30%;
}
.register .form .error-message {
  color: #bd0000;
}
.register .form .text-link {
  text-decoration: none;
  color: #bd0000;
}
.register .form .logo {
  width: 40%;
  padding-bottom: 10px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.6));
  -webkit-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.6));
  -moz-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.6));
  -ms-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.6));
  -o-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.6));
}

.nav-content {
  width: 100%;
}

.navbar-bg {
  background-color: var(--theme-nav-bg-col);
  font-family: var(--theme-body-font-bold);
  width: 100%;
  max-width: var(--theme-content-maxWidth);
}
.navbar-bg .nav-link {
  color: var(--theme-nav-text-col);
  font-weight: 400;
  font-size: 1rem;
}
.navbar-bg .nav-link.active {
  color: var(--theme-nav-text-selected-col);
}
@media only screen and (max-width: 576px) {
  .navbar-bg .nav-link {
    font-size: 0.8rem;
  }
}

.sm-logo {
  width: 100%;
  max-width: 200px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
  -webkit-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
  -moz-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
  -ms-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
  -o-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
}

.sm-logo-small {
  width: 40%;
}

.sm-username {
  border-radius: 12px;
  background-color: #ffffff;
  color: #000000;
  font-family: var(--theme-body-font-bold);
  text-align: right;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 180px;
  margin-left: auto;
  font-size: 0.9rem;
}

.main-content .left-column {
  font-family: var(--theme-body-font);
  width: 16%;
  display: block;
}
@media screen and (orientation: portrait) {
  .main-content .left-column {
    width: 0;
    display: none;
  }
}
.main-content .right-column {
  font-family: var(--theme-body-font);
  width: 16%;
  display: block;
}
@media screen and (orientation: portrait) {
  .main-content .right-column {
    width: 0;
    display: none;
  }
}
.main-content .center-column {
  width: 68%;
}
@media screen and (orientation: portrait) {
  .main-content .center-column {
    width: 100%;
  }
}
.main-content .portrait-section {
  font-family: var(--theme-body-font);
  display: none;
}
@media screen and (orientation: portrait) {
  .main-content .portrait-section {
    display: flex;
  }
}

.topbar-content .left-column {
  font-family: var(--theme-body-font);
  width: 16%;
  display: block;
}
@media screen and (orientation: portrait) {
  .topbar-content .left-column {
    width: 0;
    display: none;
  }
}
.topbar-content .right-column {
  font-family: var(--theme-body-font);
  width: 16%;
  display: block;
}
@media screen and (orientation: portrait) {
  .topbar-content .right-column {
    width: 0;
    display: none;
  }
}
.topbar-content .center-column {
  width: 68%;
}
@media screen and (orientation: portrait) {
  .topbar-content .center-column {
    width: 100%;
  }
}
.topbar-content .portrait-section {
  font-family: var(--theme-body-font);
  display: none;
}
@media screen and (orientation: portrait) {
  .topbar-content .portrait-section {
    display: flex;
  }
}

.mytournamentsHeader {
  font-family: var(--theme-body-font-bold);
}

.container-page {
  width: 100%;
  max-width: var(--theme-content-maxWidth);
  margin-left: auto;
  margin-right: auto;
  height: auto;
  font-family: var(--theme-body-font);
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
}
.container-page .text-link {
  text-decoration: none;
  color: var(--theme-secondary-color);
}
.container-page .content-page h3 {
  font-family: var(--theme-body-font-bold);
  padding-bottom: 10px;
}
.container-page .content-page .page-header {
  font-family: var(--theme-body-font-bold);
  font-size: 1.1rem;
}
.container-page .content-page .page-desc {
  padding-bottom: 20px;
}
.container-page .content-page .page-button {
  font-family: var(--theme-body-font-bold);
  padding: 1px;
  background-color: var(--theme-button-color);
  width: auto;
  display: block;
  color: var(--theme-font-color);
  text-decoration: none;
  border-radius: 2px;
}
.container-page .content-page #tutorialsPlayer {
  width: 100%;
}

.tournament-card {
  background-color: var(--theme-primary-color);
  background: linear-gradient(0deg, var(--theme-primary-color) 0%, var(--theme-light-color) 35%, var(--theme-primary-color) 100%);
  /*filter: alpha(opacity=90);
      -moz-opacity: 0.9;
      opacity: 0.9;*/
  font-family: var(--theme-body-font);
  width: 100%;
  max-width: var(--theme-content-maxWidth);
  margin-left: auto;
  margin-right: auto;
  height: auto;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
  -webkit-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
  -moz-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
  -ms-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
  -o-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
  border-radius: 5px;
  color: var(--theme-font-color);
  margin-bottom: 20px;
}
.tournament-card.completed {
  background: linear-gradient(0deg, var(--theme-completed-color) 0%, var(--theme-completed-color2) 35%, var(--theme-completed-color) 100%);
  filter: alpha(opacity=90);
  -moz-opacity: 0.9;
  opacity: 0.9;
}
.tournament-card.upcoming {
  background: linear-gradient(0deg, var(--theme-upcoming-color) 0%, var(--theme-upcoming-color2) 35%, var(--theme-upcoming-color) 100%);
  filter: alpha(opacity=90);
  -moz-opacity: 0.9;
  opacity: 0.9;
}
.tournament-card .card-maintitle {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-family: var(--theme-heading-font);
  padding: 10px;
}
.tournament-card .card-title {
  font-size: 1.5rem;
  font-family: var(--theme-body-font-bold);
}
.tournament-card .card-title-sm {
  font-size: 1.3rem;
  font-family: var(--theme-body-font-bold);
}
@media only screen and (max-width: 576px) {
  .tournament-card .card-title-sm {
    font-size: 1rem;
  }
}
.tournament-card .card-subtitle {
  font-size: 1.2rem;
  font-family: var(--theme-body-font-bold);
}
.tournament-card .card-row {
  display: flex;
  flex-wrap: wrap;
}
.tournament-card .card-image {
  width: 90%;
  max-width: 230px;
  background-color: rgba(255, 255, 255, 0.2);
}
.tournament-card .minView {
  display: none;
}
.tournament-card .card-body {
  margin: 10px;
}
.tournament-card .card-body .card-heading {
  font-size: 1rem;
  line-height: 1rem;
}
.tournament-card .card-body .card-detail {
  font-size: 1.2rem;
  font-family: var(--theme-body-font-bold);
}
@media only screen and (max-width: 576px) {
  .tournament-card .card-body .card-detail {
    font-size: 0.9rem;
  }
}
.tournament-card .card-body .card-playButton {
  font-family: var(--theme-heading-font);
  font-size: 1.2rem;
  padding: 8px 40px;
  background-color: var(--theme-button-color);
  background: linear-gradient(0deg, var(--theme-button-color2) 0%, var(--theme-button-color) 100%);
  cursor: pointer;
  width: fit-content;
  display: block;
  color: var(--theme-font-color);
  text-decoration: none;
  border-radius: var(--theme-button-radius);
}
.tournament-card .card-section {
  padding: 10px;
}
.tournament-card .card-weapons {
  padding: 10px 10px;
}
.tournament-card .card-weapons .card-weapon-img {
  width: 90%;
}
.tournament-card .card-weapons .card-weapon-title {
  font-family: var(--theme-heading-font);
  font-size: 0.9rem;
  text-align: center;
}
@media only screen and (max-width: 576px) {
  .tournament-card .card-weapons .card-weapon-title {
    font-size: 0.8rem;
  }
}
.tournament-card .card-weapons .card-weapon-container {
  position: relative;
  text-align: center;
}
.tournament-card .card-weapons .card-weapon-usage {
  font-family: var(--theme-heading-font);
  position: absolute;
  bottom: 1px;
  width: 100%;
  font-size: 0.9rem;
  text-align: center;
}
@media only screen and (max-width: 576px) {
  .tournament-card .card-weapons .card-weapon-usage {
    font-size: 0.7rem;
    bottom: -3px;
  }
}
.tournament-card .card-weapons .infinitySymbol {
  filter: invert(100%) sepia(0%) saturate(31%) hue-rotate(140deg) brightness(106%) contrast(108%);
  width: 16px;
}
@media only screen and (max-width: 768px) {
  .tournament-card .card-weapons .infinitySymbol {
    width: 12px;
  }
}
.tournament-card .card-weapons .card-weapon-usage-top {
  top: 2px;
}
@media only screen and (max-width: 768px) {
  .tournament-card .card-weapons .card-weapon-usage-top {
    font-size: 0.6rem;
    top: 1px;
  }
}
.tournament-card .card-section-expand .card-banner {
  cursor: pointer;
  background-image: none;
  background-color: var(--theme-secondary-color);
  padding: 10px 20px;
}
.tournament-card .card-section-expand .card-banner .card-title {
  font-size: 1.4rem;
}
.tournament-card .card-section-expand .card-banner.white {
  border-top: 4px solid;
  border-top-color: #ffffff;
}
.tournament-card .card-section-expand .card-desc {
  font-family: var(--theme-body-font-bold);
}
.tournament-card .card-section-expand .card-desc .card-desc-title {
  font-size: 0.7rem;
  line-height: 0.7rem;
}
.tournament-card .card-section-expand .card-desc .card-desc-text {
  font-size: 1rem;
  line-height: 1rem;
}
@media only screen and (max-width: 576px) {
  .tournament-card .card-section-expand .card-desc .card-desc-text {
    font-size: 0.7rem;
    line-height: 0.7rem;
  }
}
.tournament-card .card-section-expand .weapon-title {
  font-size: 0.9rem;
  line-height: 0.9rem;
}
@media only screen and (max-width: 576px) {
  .tournament-card .card-section-expand .weapon-title {
    padding-left: 10px;
    font-size: 0.7rem;
    line-height: 0.7rem;
  }
}
.tournament-card .entain-table-sm-icons {
  vertical-align: middle;
  width: 25px;
}
.tournament-card .white-icon {
  filter: invert(94%) sepia(90%) saturate(5083%) hue-rotate(184deg) brightness(133%) contrast(100%);
  -webkit-filter: invert(94%) sepia(90%) saturate(5083%) hue-rotate(184deg) brightness(133%) contrast(100%);
  -moz-filter: invert(94%) sepia(90%) saturate(5083%) hue-rotate(184deg) brightness(133%) contrast(100%);
  -ms-filter: invert(94%) sepia(90%) saturate(5083%) hue-rotate(184deg) brightness(133%) contrast(100%);
  -o-filter: invert(94%) sepia(90%) saturate(5083%) hue-rotate(184deg) brightness(133%) contrast(100%);
}

.card-arrow {
  text-align: right;
  height: 100%;
  width: 20px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
.card-arrow.down {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
}
.card-arrow.left {
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
}
.card-arrow.right {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.carousel-container {
  position: relative;
}
.carousel-container .carousel-holder {
  overflow: hidden;
  width: 90%;
  margin-left: 5%;
}
.carousel-container .left-arrow {
  padding-top: 10%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0);
  height: auto;
}
.carousel-container .left-arrow.top {
  padding-top: 5%;
}
.carousel-container .right-arrow {
  padding-top: 10%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0);
  height: auto;
}
.carousel-container .right-arrow.top {
  padding-top: 5%;
}

.card-table {
  background-color: #ffffff;
  padding: 20px;
  color: var(--theme-table-font-colour);
  font-size: 0.8rem;
  text-align: center;
}
.card-table .table-row:nth-child(odd) .table-col div {
  background-color: var(--theme-table-row-odd);
}
.card-table .table-row.highlight .table-col div {
  background-color: var(--theme-table-row-highlight);
}
.card-table .table-col div {
  background-color: var(--theme-table-row);
  height: 100%;
}
.card-table .table-col div.title {
  background-color: var(--theme-secondary-color);
  font-family: var(--theme-body-font-bold);
  color: #ffffff;
}

.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice > span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice > p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.7rem;
  /*justify-content: center;
  padding: 0.5rem;
  border: 1px solid #ebebeb;
  color: #000;
  border-radius: 0.25rem;*/
  text-decoration: none;
}
@media only screen and (max-width: 576px) {
  .show-counter .countdown-link {
    font-size: 0.8rem;
    line-height: 1.3rem;
  }
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 2px 0 2px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}
@media only screen and (max-width: 576px) {
  .show-counter .countdown > span {
    font-size: 0.5rem;
    line-height: 0.9rem;
  }
}

.popupContainer {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.popupContainer .contentContainer {
  border-radius: 10px;
  width: 320px;
  height: auto;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  position: absolute;
  top: 10px;
  left: 50%;
  margin-top: 10px;
  margin-left: -160px;
}
.popupContainer .contentContainer .content {
  padding: 40px 20px;
  text-align: center;
  font-size: 0.9rem;
  font-family: "Arial";
  color: #000000;
}
.popupContainer .contentContainer .content .buttons {
  margin-top: 20px;
}
.popupContainer .contentContainer .content .buttons .ctabtn {
  padding: 3px;
  margin: 5px;
  display: block;
  max-width: 100%;
  color: #ffffff;
  background-color: #8bbd23;
  cursor: pointer;
  border-radius: 2px;
  background-image: linear-gradient(to bottom, #8bbd23, #8bbd23);
}
.popupContainer .contentContainer .content .buttons .ctabtn :hover {
  cursor: pointer;
  background-image: linear-gradient(to top, #8bbd23, #8bbd23);
}

@font-face {
  font-family: "germania_oneregular";
  font-style: normal;
  font-weight: normal; /* or 400 */
  /*src: url("fonts/MyriadProBold.ttf") format('truetype');*/
  src: url("../fonts/germaniaone-regular-webfont.eot"); /* IE9 Compat Modes */
  src: url("../fonts/germaniaone-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/germaniaone-regular-webfont.woff") format("woff"), url("../fonts/germaniaone-regular-webfont.ttf") format("truetype");
}
@font-face {
  font-family: "sf_ns_display_condensedbold";
  src: url("../fonts/sfnsdisplaycondensed-bold-webfont.eot");
  src: url("../fonts/sfnsdisplaycondensed-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/sfnsdisplaycondensed-bold-webfont.woff2") format("woff2"), url("../fonts/sfnsdisplaycondensed-bold-webfont.woff") format("woff"), url("../fonts/sfnsdisplaycondensed-bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "oswald_regularregular";
  src: url("../fonts/oswald-regular-webfont.woff2") format("woff2"), url("../fonts/oswald-regular-webfont.woff") format("woff"), url("../fonts/Oswald-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "oswaldlight";
  src: url("../fonts/oswald-light-webfont.eot");
  src: url("../fonts/oswald-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/oswald-light-webfont.woff2") format("woff2"), url("../fonts/oswald-light-webfont.woff") format("woff"), url("../fonts/oswald-light-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "oswaldmedium";
  src: url("../fonts/oswald-medium-webfont.woff2") format("woff2"), url("../fonts/oswald-medium-webfont.woff") format("woff"), url("../fonts/Oswald-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root {
  --theme-heading-font:oswald_regularregular;
  --theme-body-font:oswaldlight;
  --theme-body-font-bold:oswald_regularregular;
  --theme-body-bg:url("../images/bg.png");
  --theme-primary-color:#bd0000;
  --theme-light-color:#ff2a2c;
  --theme-dark-color:#691314;
  --theme-dark-color2:#bd0000;
  --theme-secondary-color:#413d3c;
  --theme-font-color:#ffffff;
  --theme-selected-color:#0ed014;
  --theme-button-color:#0ed014;
  --theme-button-color2:#098b0d;
  --theme-button-radius:5px;
  --theme-nav-bg-col:#413d3c;
  --theme-nav-text-col:#ffffff;
  --theme-nav-text-selected-col:#0ed014;
  --theme-completed-color:#691314;
  --theme-completed-color2:#bd0000;
  --theme-upcoming-color:#05375f;
  --theme-upcoming-color2:#065aa1;
  --theme-table-row:#e9e9e9;
  --theme-table-row-odd:#cdcdcd;
  --theme-table-row-highlight:#ffe5d4;
  --theme-table-font-colour:#000000;
  --bs-body-bg: null;
  --bs-body-font-family:--theme-body-font;
  --theme-content-maxWidth: 600px;
}

html {
  background: var(--theme-body-bg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
